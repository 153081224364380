@tailwind base;
@tailwind components;
@tailwind utilities;

.ws__icon {
  @apply p-2 h-10 w-10 text-black;
}

.ws__icon-small-white {
  @apply mx-1 my-0 h-3 w-3 text-white;
}
.ws__icon-white {
  @apply mx-4 my-0 h-10 w-10 text-white;
}
h1 {
  @apply text-2xl;
}
h2 {
  @apply text-xl;
}
h3 {
  @apply text-lg;
}
.ws__custom-scrollbar::-webkit-scrollbar-track {
  background: #55b848;
}
.ws__custom-scrollbar-rounded-track::-webkit-scrollbar-track {
  background: #3d4d42;
  border-radius: 10px;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #3d4d42;
  /* border-radius: 10px; */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #58b031;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(22, 163, 74);
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.noscroller::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.noscroller {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
